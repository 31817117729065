import React, { useEffect, useState } from 'react'
import { withTrans } from '../../i18n/withTrans'
import { useTranslation } from 'react-i18next'
import LocalizedLink from '../LocalizedLink'
import MetaTags from '../MetaTags'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'

import './hair-quiz.scss'
import model_quiz from '../../images/quiz/model-hair-quiz.webp'
import banner_desktop from '../../images/socmed/banner-socmed-desktop.webp'

const HairQuiz = ({ pageContext }) => {
    const { t } = useTranslation()

    useEffect(() => {
        const isBrowser = typeof window !== 'undefined'
        const AOS = isBrowser ? require('aos') : undefined

        if (AOS) {
            AOS.init()
        }

        return () => {
            if (AOS) {
                AOS.refresh()
            }
        }
    }, [])

    return (
        <div className="quiz-page">
            <MetaTags
                title="Ellips | Hair Test Quiz"
                description={`${t('quiz.meta-desc')}`}
                image={banner_desktop}
                url={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <Navigation activeLanguage={pageContext.lang} />
            <div className="quiz-page-container">
                <div className="box-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 d-none d-md-flex">
                                <div className="box-left">
                                    <div className="box-img">
                                        <img
                                            src={model_quiz}
                                            alt="image"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="box-right">
                                    <div className="box-img d-block d-md-none">
                                        <img
                                            src={model_quiz}
                                            alt="image"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="box-title">
                                        <h1 className="title">
                                            Ellips Hair Quiz
                                        </h1>
                                    </div>
                                    <div className="box-desc">
                                        <p className="text mont-regular">
                                            Our proprietary diagnostic tool
                                            combines powerful data and advanced
                                            logic to give you an accurate hair
                                            profile, complete with customized
                                            routines and products.
                                        </p>
                                    </div>
                                    <div className="box-button">
                                        <LocalizedLink
                                            to="/hair-quiz-start"
                                            className="btn btn-outline-primary btn-outline-primary__outline-black see-detail grow shrink mont-regular"
                                            target="_self"
                                        >
                                            Take Hair Quiz
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withTrans(HairQuiz)